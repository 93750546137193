@import "../../const/settings.scss";
.wp-reviewDentistry {
  max-width: 1200px;
  display: flex;
  margin: 80px auto;
  flex-direction: column;
  align-items: center;
  .wp-title {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    width: 100%;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    // align-items: flex-start;
    .moreButtonDesktop {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0 15px;
      // height: 40px;
      border-radius: 8px;
      // border: 1px solid #00c5d7;
      background-color: #fff;
      cursor: pointer;
      // margin-top: 30px;
      p {
        color: #00c5d7;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        margin-bottom: 0;
        margin-right: 4px;
      }
      .more-button-img {
        width: 20px !important;
      }
    }
    h2 {
      margin-bottom: 30px;
      color: var(--Color-1, #2a3467);
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 39px */
      text-transform: uppercase;
    }
  }

  .line {
    height: 4px;
    width: 120px;
    border-radius: 3px;
    background-color: #00c5d7;
    margin-bottom: 20px;
  }
  .wp-content {
    // margin-bottom: 64px;
    width: 100%;
    .item-skeleton-blog-home {
      width: 100%;
      border-radius: 5px;
      .main {
        height: 300px;
      }
      .other {
        height: 100px;
        margin-bottom: 16px;
      }
    }
    .wp-mainPost {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .wp-mainPostImage {
        width: 100%;
        // height: 400px;
        // aspect-ratio: 8/5;

        .mainPostImage {
          aspect-ratio: 8/5;
          width: 100%;
          // height: 100%;
          object-fit: cover;
          border-radius: 8px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        margin-bottom: 20px !important;
        .ant-image {
          width: 100%;
          height: 100%;
        }
      }
      .wp-mainContent {
        .blog {
          color: #00c5d7;
          font-size: 12px;
          font-weight: 600;
          padding: 0;
          margin-bottom: 8px;
        }
        .title {
          color: var(--Color-1, #2a3467);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 30px */
          margin-bottom: 8px;
          display: flex;
          justify-content: flex-start;
        }
        .content-description {
          color: var(--Color-4, #363636);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          margin-bottom: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .see-detail {
          height: 35px !important;
          width: 115px;
          border-radius: 5px !important;
          border: 1px solid var(--Color-2, #00c5d7) !important;
          background: var(--Color-3, #fff);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 0;
          span {
            color: var(--Color-4, #363636);
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 150%; /* 21px */
            text-transform: capitalize;
          }
          @include media(md) {
            span {
              font-size: 14px !important;
            }
            height: 30px !important;
          }
        }
      }
    }
    .wp-otherPost {
      display: flex;
      flex-direction: column;
      .wp-postItem {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 15px;
        // border-radius: 16px;
        // background: #f9f9f9;
        margin-bottom: 16px;
        cursor: pointer;

        border-radius: 5px;
        background: #f5f5f5;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        .wp-postImage {
          width: 200px;
          height: auto;
          aspect-ratio: 8/5;
          margin-right: 24px;
          .postImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative !important;
            border-radius: 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
          .ant-image {
            width: 200px;
            height: 100%;
          }
        }
        .wp-postItemContent {
          display: flex;
          flex-direction: column;
          .postItemBlog {
            color: #00c5d7;
            font-size: 12px;
            font-weight: 600;
            padding: 0;
            margin-bottom: 8px;
          }
          .postItemTitle {
            color: var(--Color-1, #2a3467);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 30px */
            margin-bottom: 8px;
          }
          .postItemDesc {
            color: var(--Color-4, #363636);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-bottom: 12px;
          }
          .see-detail {
            height: 35px !important;
            width: 115px;
            border-radius: 5px !important;
            border: 1px solid var(--Color-2, #00c5d7) !important;
            background: var(--Color-3, #fff);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 0;
            span {
              color: var(--Color-4, #363636);
              font-size: 16px;
              font-style: italic;
              font-weight: 400;
              line-height: 150%; /* 21px */
              text-transform: capitalize;
            }
          }
        }
      }
      .wp-postItem:last-child {
        margin-bottom: 0;
      }
    }
  }
  .see-more-button {
    margin-top: 30px;
    display: flex;
    width: auto !important;
    height: 35px !important;
    padding: 12px 20px !important;
    flex-shrink: 0;
    border-radius: 5px !important;
    background: var(--Color-1, #2a3467) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    border: none !important;
    span {
      margin-right: 5px;
      color: var(--Color-3, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @include media(md) {
      span {
        font-size: 14px !important;
      }
      height: 30px !important;
    }
  }
}
@include media(lg) {
  .wp-reviewDentistry {
    padding: 0 15px;
  }
}
@include media(md) {
  .wp-reviewDentistry {
    margin: 30px auto 30px;

    h2 {
      font-size: 24px;
      font-weight: 600;
    }
    .line {
      margin-bottom: 28px;
    }
    .wp-content {
      margin-bottom: 0 !important;
      .wp-mainPost {
        .wp-mainPostImage {
          width: 100%;
          height: 230px;
          margin-bottom: 21px;
        }
        .wp-mainContent {
          .blog {
            font-size: 12px;
          }
          .title {
            font-size: 20px;
          }
          .content {
            font-size: 14px;
          }
        }
      }
      .wp-otherPost {
        .wp-postItem {
          padding: 14px;
          .wp-postImage {
            width: 120px;
            height: 96px;
            margin-right: 14px;
            .ant-image {
              width: 120px;
            }
          }
          .wp-postItemContent {
            .postItemBlog {
              font-size: 12px;
            }
            .postItemTitle {
              color: #2a3467;
              font-size: 14px;
              font-weight: 600;
              line-height: 130%;
            }
          }
        }
      }
    }
  }
}
